import {useEffect, useState} from 'react'
import Icon from '@ant-design/icons'
import {Layout, Menu, Tooltip, Typography} from 'antd'
import {ADMIN_PREFIX_PATH, APP_NAME, APP_PREFIX_PATH} from 'configs/AppConfig'
import {FormattedMessage, useIntl} from 'react-intl'
import {Link, useHistory} from 'react-router-dom'
import utils from 'utils'
import {ContentNavSVG, SherpaIcon, SherpaiLogo} from '../../../assets/logos/icon'
import CustomIcon from '../../util-components/CustomIcon'
import NavPanel from '../Header/NavPanel'

const {Sider} = Layout
const {SubMenu, Item} = Menu

const SidebarToggler = ({toggleCollapsed, collapsed}) => {
  const [rotationAngle, setRotationAngle] = useState(0)

  const handleToggle = () => {
    toggleCollapsed()
    setRotationAngle((prevAngle) => prevAngle + (collapsed ? 360 : -360)) // Incrémente l'angle
  }

  return (
    <span onClick={handleToggle} style={{cursor: 'pointer'}}>
      <CustomIcon
        svg={SherpaIcon}
        style={{
          width: 40,
          height: 35,
          marginLeft: 5,
          marginRight: 15,
          marginTop: -15,
          transition: 'transform 0.5s linear',
          transform: `rotate(${rotationAngle}deg)`
        }}
      />
    </span>
  )
}

const SidebarLogo = ({collapsed, context}) => {
  return (
    <div style={{width: '100%', height: 30}}>
      <span>
        <Link
          to={`${APP_PREFIX_PATH}/dashboard`}
          style={
            collapsed
              ? {display: 'none', transition: 'opacity 0.2s ease'}
              : {display: 'block', transition: 'opacity 1s ease'}
          }
        >
          <CustomIcon svg={SherpaiLogo} style={{width: 110}} />
        </Link>
      </span>
      <div
        style={
          collapsed
            ? {display: 'none', transition: 'opacity 0.2s ease'}
            : {display: 'block', transition: 'opacity 1s ease'}
        }
      >
        {context === 'admin' && (
          <Typography
            style={{
              color: 'white',
              fontWeight: 800,
              textAlign: 'end',
              paddingRight: 60,
              marginTop: -8
            }}
          >
            Admin
          </Typography>
        )}
      </div>
    </div>
  )
}

export default function Sidebar({location, navigation, toggleCollapsed, collapsed, context}) {
  const intl = useIntl()
  const history = useHistory()
  const [scope, setScope] = useState(navigation[0].key)
  const [currentNavKey, setCurrentNavKey] = useState(navigation[0]?.submenu[0]?.key || 0)

  useEffect(() => {
    const currentRouteInfos = utils.getRouteInfo(navigation, location.pathname)
    if (currentRouteInfos) {
      document.title = `${intl.formatMessage({
        id: currentRouteInfos.headerTitle
      })} - ${APP_NAME}`
      setScope(currentRouteInfos.parent ? currentRouteInfos.parent : currentRouteInfos)
      setCurrentNavKey(currentRouteInfos.key)
    } else {
      const scope = location.pathname.split('/')
      if (scope.length > 2) {
        setScope(scope[3])
        document.title = `${APP_NAME}`
      }
      setCurrentNavKey('')
    }
  }, [location])

  const setClientIdReport = (id) => {
    localStorage.setItem('clientReport', id)
  }

  const renderItem = (item, parentKey, collapsed) => {
    const isExternalLink = item.path.includes(`${ADMIN_PREFIX_PATH}/MailReport`)

    if (parentKey) {
      if (collapsed) {
        return (
          <>
            <Icon
              component={
                utils.userIsDemo() ? item?.icon || ContentNavSVG : item?.iconAlternate || item?.icon || ContentNavSVG
              }
              className="icon"
              style={{
                paddingLeft: 10,
                color: item.key === currentNavKey ? '#7167fc' : '#b2b2b2'
              }}
            />

            {isExternalLink ? (
              <a
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setClientIdReport && setClientIdReport(item.sys_client)}
              >
                <span className="subItem">
                  <FormattedMessage id={item.title} />
                </span>
              </a>
            ) : (
              <Link to={item.path}>
                <span style={{color: item.key === currentNavKey ? '#7167fc' : '#b2b2b2'}}>
                  <FormattedMessage id={item.title} />
                </span>
              </Link>
            )}
          </>
        )
      } else {
        return (
          <>
            <Icon
              component={
                utils.userIsDemo() ? item?.icon || ContentNavSVG : item?.iconAlternate || item?.icon || ContentNavSVG
              }
              className="icon"
              style={{
                marginLeft: 5,
                color: item.key === currentNavKey ? '#7167fc' : '#b2b2b2'
              }}
            />

            {isExternalLink ? (
              <a
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setClientIdReport && setClientIdReport(item.sys_client)}
              >
                <span>
                  <FormattedMessage id={item.title} />
                </span>
              </a>
            ) : (
              <Link to={item.path}>
                <span style={{color: item.key === currentNavKey ? '#7167fc' : '#b2b2b2'}}>
                  <FormattedMessage id={item.title} />
                </span>
              </Link>
            )}
          </>
        )
      }
    } else {
      if (collapsed) {
        return (
          <div
            className={`${currentNavKey === item.key ? 'sidebar-menu-item-selected' : ''}`}
            style={{width: '100%', height: '100%'}}
          >
            <Tooltip
              arrowPointAtCenter={false}
              placement="rightTop"
              color="#0B0B2D"
              title={
                <span className="sidebar-tooltip-title">
                  <FormattedMessage id={item.title} />
                </span>
              }
              style={{position: 'relative'}}
              zIndex={9999999999999}
            >
              <Link to={item.path}>
                <Icon
                  component={
                    utils.userIsDemo()
                      ? item?.icon || ContentNavSVG
                      : item?.iconAlternate || item?.icon || ContentNavSVG
                  }
                  className="icon"
                  style={{marginLeft: '-12px', width: 63}}
                />
              </Link>
            </Tooltip>
          </div>
        )
      } else {
        return (
          <div
            className={`${currentNavKey === item.key ? 'sidebar-menu-item-selected' : ''}`}
            style={{width: '100%', height: '100%'}}
          >
            <Icon
              component={
                utils.userIsDemo() ? item?.icon || ContentNavSVG : item?.iconAlternate || item?.icon || ContentNavSVG
              }
              className="icon"
              style={{paddingLeft: 12, color: item.key === currentNavKey ? '#7167fc' : '#b2b2b2'}}
            />

            <Link to={item.path}>
              <span className="sidebar-main-item" style={{color: item.key === currentNavKey ? '#7167fc' : 'white'}}>
                <FormattedMessage id={item.title} />
              </span>
            </Link>
          </div>
        )
      }
    }
  }

  const renderMenuItems = (items, parentKey = '') => {
    return items.map((item) => {
      if (utils.checkPageAccess(item)) {
        if (item.submenu?.length && item.submenu?.length > 0) {
          return (
            <SubMenu
              key={item.key}
              className={`menu-submenu ${item.key === scope ? 'ant-menu-submenu-open' : ''}`}
              // UNCOMMENT : use inline mode
              // mode={collapsed ? 'vertical' : 'inline'}
              mode="vertical"
              style={{fontWeight: 'bold', marginTop: 0, marginBottom: 0, minHeight: 47}}
              onTitleClick={() => {
                setScope(item.key)

                const validSubmenuItem = item.submenu.find((sub) => utils.checkPageAccess(sub))
                if (validSubmenuItem && validSubmenuItem.disableQuickOpen !== true) {
                  history.push(validSubmenuItem.path)
                }
              }}
              icon={
                <Icon
                  component={
                    utils.userIsDemo()
                      ? item?.icon || ContentNavSVG
                      : item?.iconAlternate || item?.icon || ContentNavSVG
                  }
                  className="icon"
                />
              }
              title={
                <span className="sidebar-main-item" style={collapsed ? {paddingLeft: 6} : {}}>
                  {item.ico}
                  {collapsed ? (
                    !parentKey ? (
                      <div className="subItem">
                        <FormattedMessage id={item.title} />
                      </div>
                    ) : (
                      <span className="subItem">
                        <FormattedMessage id={item.title} />
                      </span>
                    )
                  ) : (
                    <span>
                      <FormattedMessage id={item.title} />
                    </span>
                  )}
                </span>
              }
            >
              {renderMenuItems(item.submenu, parentKey + item.key + '.')}
            </SubMenu>
          )
        } else {
          return (
            <Item
              // UNCOMMENT : use inline mode
              // className={
              //   'sidebar-menu-default:hover ' +
              //   (parentKey
              //     ? collapsed
              //       ? 'sidebar-submenu-item-collapsed'
              //       : 'sidebar-submenu-item'
              //     : collapsed
              //     ? 'sidebar-menu-item-collapsed'
              //     : 'sidebar-menu-item')
              // }
              className={'sidebar-menu-default:hover sidebar-menu-item-collapsed'}
              key={parentKey + item.key}
              style={{
                minHeight: 47,
                fontWeight: !parentKey ? 'bold' : 'normal',
                color: !parentKey ? '#b2b2b2' : 'white',
                backgroundColor: !parentKey ? 'transparent' : 'transparent',
                marginTop: !parentKey ? 2 : 0,
                marginBottom: !parentKey ? 2 : 0
              }}
            >
              {renderItem(item, parentKey, collapsed)}
            </Item>
          )
        }
      }

      return null
    })
  }

  return (
    <>
      <Sider
        className="sider-app"
        collapsed={collapsed}
        width="240px"
        style={{backgroundColor: '#0b0b2d', userSelect: 'none'}}
      >
        <div style={{height: 50, marginTop: 30, marginBottom: 30, paddingLeft: 15}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <SidebarToggler collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            <SidebarLogo collapsed={collapsed} context={context} />
          </div>
        </div>

        <div className="menu-sidebar">
          {scope && (
            <Menu
              mode="vertical"
              className="menu"
              selectedKeys={currentNavKey}
              style={{fontWeight: '500', fontSize: '1.05rem', backgroundColor: 'transparent'}}
              // UNCOMMENT : use inline mode
              // {...(!collapsed && {openKeys: [scope]})}
            >
              {renderMenuItems(navigation)}
            </Menu>
          )}
        </div>

        <div style={{margin: 'auto auto 0 auto', marginLeft: collapsed ? 20 : 15}}>
          <NavPanel collapsed={collapsed} history={history} location={location} />
        </div>
      </Sider>

      <style>{`
        .menu-sidebar {
          overflow: scroll; /* Permet le défilement */
          scrollbar-width: none; /* Cache la scrollbar (Firefox) */
          -ms-overflow-style: none
        }
        .sidebar-tooltip-title {
          font-size: 14px;
          font-weight: 500;
          color: #b2b2b2;
          user-select: none;
          font-family: 'Manrope', sans-serif !important;
        }
        .sidebar-menu-item-selected::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 3.5px; /* Largeur de la bande */
          height: 100%;
          background-color: white; /* Couleur de la bande */
          border-top-right-radius: 10px; /* Arrondi du coin supérieur droit */
          border-bottom-right-radius: 10px; /* Arrondi du coin inférieur droit */
        }
        .menu::-webkit-scrollbar {
          display: none;
        }
        .subItem {
          color: #9e9e9e;
          transition: 0.2s;
        }
        .subItem:hover {
          color: white;
          transition: 0.2s;
        }
        .sidebar-menu-default:hover {
          background-color: #1E1E47;
        }
        .sidebar-menu-item {
          padding: 0px!important;
        }
        .sidebar-menu-item-collapsed {
          padding: 0px!important;
        }
        .sidebar-submenu-item {
          padding: 0px!important;
          padding-left: 40px!important;
        }
        .sidebar-submenu-item-collapsed {
          padding: 5px!important;
        }
        .hover-bar:hover {
          background-color: #1E1E47;
        }
        .menu ul {
          background-color: transparent!important;
        }
        .sidebar-main-item {
          font-size: 1.1em!important;
        }
      `}</style>
    </>
  )
}
