import {Component} from 'react'
import {Button} from 'antd'
import {Link} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  componentDidCatch(error, info) {
    // You can also log the error-view to an error-view reporting service
    // logErrorToMyService(error-view, info)
    console.log('error', error, info)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="h-100 bg-white">
          <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
            <div>
              <img width="120px" className="img-fluid" src="/img/sherpai_logo.png" alt="" />
            </div>
            <div className="container">
              <div className="text-center mb-5">
                <img className="img-fluid" src="/img/others/img-21.png" alt="" />
                <h1 className="font-weight-bold mb-4">
                  {' '}
                  <FormattedMessage id="navigation.unexpected.message" />
                </h1>

                <Button href="/app" type="primary">
                  <FormattedMessage id="navigation.button.backHome" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
