import axios from 'axios'
import {notification} from 'antd'
import {API_BASE_URL} from 'configs/AppConfig'
import JwtAuthService from 'services/JwtAuthService'
import {getBrowserLanguage, getPreferredLanguage, setPreferredLanguage} from '../contexts/LanguageContext'
import history from '../history'

let isMailReportRequestActive = false

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const ROUTES_NO_SECURE = [
  '/auth/login',
  '/auth/register',
  '/auth/forgot-password',
  '/auth/reset-password',
  '/auth/login0',
  // '/default/connect'
]
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(
  async (config) => {
    const token = await JwtAuthService.getIdToken()

    if (token) {
      config.headers[TOKEN_PAYLOAD_KEY] = token
    }

    const currentPath = window.location.pathname
    const routeIsNoSecure = ROUTES_NO_SECURE.some((route) => currentPath.startsWith(route))

    let preferredLanguage = getPreferredLanguage()

    if (currentPath.includes('MailReport')) {
      isMailReportRequestActive = true
      config.timeout = 60000 * 12 // Set custom timeout for mailReport
    } else if (isMailReportRequestActive) {
      config.timeout = 60000 * 12 // Apply same timeout to other requests made during mailReport request
    }

    if (!preferredLanguage && !currentPath.includes('MailReport')) {
      preferredLanguage = getBrowserLanguage()
      setPreferredLanguage(preferredLanguage)
    }
    config.headers['Accept-Language'] = preferredLanguage

    if (!routeIsNoSecure) {
      if (!token && !config.headers[PUBLIC_REQUEST_KEY]) {
        history.push(ENTRY_ROUTE)
        window.location.reload()
      }
    }

    return config
  },
  (error) => {
    // Do something with request error-view here
    notification.error({
      message: 'Error'
    })
    Promise.reject(error)
  }
)

// API respone interceptor
service.interceptors.response.use(
  (response) => response.data,
  (error) => {
    /** @todo Intl messages + more user friendly !  */
    const notificationParam = {
      message: ''
    }

    // const intl = useIntl()

    // if (!error.response || !error.response.status || error.response.status === 500) {
    //   notificationParam.message = 'Internal Server Error'
    //   openNotification(
    //     'error',
    //     'notification.error.InternalServerError.title',
    //     'notification.error.InternalServerError.message',
    //     60,
    //     // /intl,
    //   )
    // }

    // Remove token and redirect
    /** @todo Are we sure we want to remove token when error-view is 400 (bad request) ? */
    if (error.response.status === 403) {
      notificationParam.message = 'Authentication Fail'
      notificationParam.description = 'Please login again'
      JwtAuthService.removeTokens()
      history.push(ENTRY_ROUTE)
      window.location.reload()
    }

    if (error.response.status === 401) {
      notificationParam.message = 'Unauthorized'
      JwtAuthService.removeTokens()
      history.push(ENTRY_ROUTE)
      window.location.reload()
    }

    if (error.response.status === 404) {
      notificationParam.message = 'Not Found'
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out'
    }

    // notification.error-view(notificationParam)

    return Promise.reject(error)
  }
)

export default service
