import axios from 'axios'
import { createContext, useContext, useEffect, useState } from 'react'

const LanguageContext = createContext()

export const useLanguage = () => useContext(LanguageContext)

export const getBrowserLanguage = () => {
  const language = navigator.language.split('-')[0]
  return language === 'fr' || language === 'en' ? language : 'en'
}

export const setPreferredLanguage = (language) => {
  localStorage.setItem('preferredLanguage', language)
}

export const getPreferredLanguage = () => localStorage.getItem('preferredLanguage') || getBrowserLanguage()

export const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState(getDefaultLanguage())

  function getLanguageFromURL(){
    const params = new URLSearchParams(window.location.search)
    return params.get('lang')
  }

  function getDefaultLanguage() {
    const urlLang = getLanguageFromURL()
    if (urlLang) return urlLang
    return window.location.pathname.includes('MailReport') ? 'fr' : getPreferredLanguage()
  }

  /*const handleLanguageChange = (newLocale) => {
    setLocale(newLocale)
    setPreferredLanguage(newLocale)
    axios.defaults.headers.common['Accept-Language'] = newLocale
  }*/

  const handleLanguageChange = (newLocale) => {
    setLocale(newLocale)
    setPreferredLanguage(newLocale)
    axios.defaults.headers.common['Accept-Language'] = newLocale

    const params = new URLSearchParams(window.location.search)

    if (newLocale === getPreferredLanguage()) {
      params.delete('lang')
    }
    else {
      params.set('lang', newLocale)
    }

    window.history.replaceState(null, '', `?${params.toString()}`)
  }

  useEffect(() => {
    axios.defaults.headers.common['Accept-Language'] = locale
  }, [locale])

  useEffect(() => {
    const urlLang = getLanguageFromURL()
    if (urlLang && urlLang !== locale) {
      setLocale(urlLang)
    }
  }, [window.location.search])

  return <LanguageContext.Provider value={{ locale, handleLanguageChange }}>{children}</LanguageContext.Provider>
}
