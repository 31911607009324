import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {Avatar, Dropdown, List} from 'antd'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {CaretUpOutlined, UserOutlined} from '@ant-design/icons'
import {ADMIN_PREFIX_PATH} from 'configs/AppConfig'
import {changePassword, deleteUser, signOut} from 'redux/actions/Auth'
import utils from 'utils'
import CustomIcon from 'components/util-components/CustomIcon'
import LanguageSwitcher from 'components/shared-components/LanguageSwitcher'
import {LanguageNavSVG, LinkSVG, LogoutNavSVG, SettingsNavSVG, ResetPasswordSVG} from 'assets/logos/icon'
import {DEFAULT_PREFIX_PATH} from 'configs/AppConfig'
import SystemRolesService from '../../../services/System/Roles/index'

export const NavProfile = ({signOut, allowRedirect, deleteUser, changePassword, user, collapsed, handleOpenPanel}) => {
  const history = useHistory()
  const firstName = user.name ? user.name.split(' ')[0] : user.email
  const [showLanguageSwitcher, setShowLanguageSwitcher] = useState(false)
  const lang = localStorage.getItem('preferredLanguage')

  const handleLanguageClick = (e) => {
    e.stopPropagation()
    setShowLanguageSwitcher(true)
  }

  const handleResetPasswordClick = async (values) => {
    let response = await SystemRolesService.newResetPassword({email: user.email})
    // Check if response.link includes '/auth'
    if (response.link.includes('/auth')) {
      response.link = response.link.substring(response.link.indexOf('/auth'))
    }
    history.push(response.link)
  }

  const handleLanguageSwitch = () => {
    setShowLanguageSwitcher(false)
  }

  const handleAdminClick = () => {
    history.push(`${ADMIN_PREFIX_PATH}/`)
  }

  const handleMailReportClick = () => {
    window.open(`${ADMIN_PREFIX_PATH}/MailReport`, '_blank')
  }

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-body">
        <List className="menu-profile-panel" split={false}>
          {utils.checkUserAccess(['admin', 'sysadmin', 'accountmanager']) && (
            <List.Item key={1} onClick={handleAdminClick}>
              <span>
                <CustomIcon svg={SettingsNavSVG} />
                <span>
                  <FormattedMessage id="headernav.menu.admin" />
                </span>
              </span>
            </List.Item>
          )}
          {/*{utils.checkUserAccess(['admin', 'sysadmin']) && (
            <List.Item key={1} onClick={handleMailReportClick}>
              <span>
                <FilePdfOutlined />
                <span>
                  <FormattedMessage id="Rapport" />
                </span>
              </span>
            </List.Item>
          )}*/}

          {/* <List.Item
            split={false}
            key={2}
            onClick={() => {
              window.open(env.documentation.baseUrl, '_blank')
            }}
          >
            <span>
              <CustomIcon svg={BookSVG} />
              <FormattedMessage id="headernav.definitions" />
            </span>
          </List.Item> */}

          <List.Item
            key={3}
            onClick={() => {
              window.open(`${DEFAULT_PREFIX_PATH}/legal-notice?lang=${lang}`, '_blank')
            }}
          >
            <span>
              <CustomIcon svg={LinkSVG} />
              <FormattedMessage id="headernav.legalNotice" />
            </span>
          </List.Item>

          <List.Item key={4} onClick={handleLanguageClick}>
            {showLanguageSwitcher ? (
              <LanguageSwitcher onLanguageClick={handleLanguageSwitch} />
            ) : (
              <span onClick={handleLanguageClick}>
                <CustomIcon svg={LanguageNavSVG} />
                <FormattedMessage id="headernav.language" />
              </span>
            )}
          </List.Item>

          <List.Item key={1} onClick={handleResetPasswordClick} allowRedirect>
            <span>
              <CustomIcon svg={ResetPasswordSVG} />
              <span>
                <FormattedMessage id="headernav.menu.resetPassord" />
              </span>
            </span>
          </List.Item>

          <List.Item key={5} onClick={(e) => signOut()}>
            <span>
              <CustomIcon svg={LogoutNavSVG} />
              <FormattedMessage id="headernav.signout" />
            </span>
          </List.Item>

          {/* <Menu.Item key={menuItem.length + 2} onClick={(e) => deleteUser()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <FormattedMessage id="headernav.delete.user" />
            </span>
          </Menu.Item> */}
        </List>
      </div>
    </div>
  )

  return (
    <Dropdown className="profile" placement="topLeft" overlay={profileMenu} trigger={['click']}>
      <div className="profile-infos">
        <Avatar shape="circle" size={34} src={user.picture} icon={<UserOutlined />} style={{color: '#4a3aff'}} />
        {!collapsed && (
          <>
            <span className="first-name">{firstName}</span>
            <span className="dropdown-arrow">
              <CaretUpOutlined style={{fontSize: '16px'}} />
            </span>
          </>
        )}
      </div>
    </Dropdown>
  )
}

export default connect(null, {signOut, deleteUser, changePassword})(NavProfile)
