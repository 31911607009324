import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const SharedDateFilterContext = createContext()

// Liste des valeurs autorisées
const ALLOWED_DATE_FILTERS = [
  'today',
  'yesterday',
  '7days',
  'last28Days',
  'last28days',
  '28days',
  '30days',
  '90days',
  'thismonth',
  'thisMonth',
  'lastmonth',
  'lastMonth',
  'lastyear',
  'lastYear',
  'thisyear',
  'thisYear',
  'alltime',
  'allTime',
]

export const useSharedDateFilter = () => useContext(SharedDateFilterContext)

// Fonction pour obtenir la valeur par défaut
export const getDefaultDateFilter = () => {
  const params = new URLSearchParams(window.location.search)
  const filter = params.get('dateFilter') || localStorage.getItem('sharedDateFilter') || '30days'
  return ALLOWED_DATE_FILTERS.includes(filter) ? filter : '30days'
}

// Fonction pour enregistrer une valeur dans localStorage
export const setPreferredDateFilter = (filter) => {
  if (ALLOWED_DATE_FILTERS.includes(filter)) {
    localStorage.setItem('sharedDateFilter', filter)
  }
}

export const SharedDateFilterProvider = ({ children }) => {
  const location = useLocation()
  const [sharedDateFilter, setSharedDateFilter] = useState(getDefaultDateFilter())

  // Met à jour le filtre partagé uniquement si la valeur est valide
  const updateSharedDateFilter = (newFilter) => {
    if (!ALLOWED_DATE_FILTERS.includes(newFilter)) {
      console.warn(`Invalid date filter: ${newFilter}`)
      return
    }

    setSharedDateFilter(newFilter)
    setPreferredDateFilter(newFilter)

    const params = new URLSearchParams(window.location.search)
    if (newFilter === localStorage.getItem('sharedDateFilter')) {
      params.delete('dateFilter')
    }
    else {
      params.set('dateFilter', newFilter)
    }
    window.history.replaceState(null, '', `?${params.toString()}`)
  }

  // Synchronise avec l'URL
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const urlFilter = params.get('dateFilter')
    if (urlFilter && ALLOWED_DATE_FILTERS.includes(urlFilter) && urlFilter !== sharedDateFilter) {
      setSharedDateFilter(urlFilter)
    }
  }, [location.search])

  return (
    <SharedDateFilterContext.Provider value={{ sharedDateFilter, updateSharedDateFilter }}>
      {children}
    </SharedDateFilterContext.Provider>
  )
}
