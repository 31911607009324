import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import {AUTH_PREFIX_PATH, DEFAULT_PREFIX_PATH} from 'configs/AppConfig'

export const DefaultViews = () => (
  <Suspense fallback={<Loading cover="page" />}>
    <Switch>
      <Route path={`${DEFAULT_PREFIX_PATH}/legal-notice`} component={lazy(() => import(`./info/legal-notice`))} />
      <Route path={`${DEFAULT_PREFIX_PATH}/cgu`} component={lazy(() => import(`./info/cgu`))} />
      {/* <Route path={`${DEFAULT_PREFIX_PATH}/connect`} component={lazy(() => import(`./connect`))} /> */}
      <Redirect to={`${AUTH_PREFIX_PATH}/login`} />
    </Switch>
  </Suspense>
)

export default DefaultViews
