import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import {AUTH_PREFIX_PATH} from 'configs/AppConfig'
import utils from 'utils'

export const AppViews = () => (
  <Suspense fallback={<Loading cover="page" />}>
    <Switch>
      <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />
      <Route path={`${AUTH_PREFIX_PATH}/loginit`} component={lazy(() => import(`./authentication/loginit`))} />
      <Route path={`${AUTH_PREFIX_PATH}/register/:token`} component={lazy(() => import(`./authentication/register`))} />
      <Route
        path={`${AUTH_PREFIX_PATH}/forgot-password`}
        component={lazy(() => import(`./authentication/forgot-password`))}
      />
      <Route
        path={`${AUTH_PREFIX_PATH}/reset-password/:token`}
        component={lazy(() => import(`./authentication/reset-password`))}
      />
      <Route
        exact
        path={`${AUTH_PREFIX_PATH}/politique_confidentialite.pdf`}
        component={() => {
          window.location.href = `${utils.getBucketUrlDocs('politique_confidentialite.pdf', 'french')}`
          return null
        }}
      />
      <Route
        exact
        path={`${AUTH_PREFIX_PATH}/cguv.pdf`}
        component={() => {
          window.location.href = `${utils.getBucketUrlDocs('cguv.pdf', 'french')}`
          return null
        }}
      />
      <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
    </Switch>
  </Suspense>
)

export default AppViews
